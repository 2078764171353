import React from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import formService from "../../services/forms/form.service";
import { AnketRequestDtoV3 } from "../../services/forms/form.dto";
import { useToast } from "../../utils/toast";
import FormikValueIsValid from "../../utils/FormikValueIsValid";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";

const Degerleme = () => {
  const toast = useToast();

  const formik = useFormik<AnketRequestDtoV3>({
    initialValues: {
      result1: "",
      result2: "",
      result3: ""
    },
    validate: values => {
      const errors: any = {};
      if (!values.result1 || values.result1 === "") {
        errors.result1 = "Bir seçenek şeçiniz.";
      }
      if (!values.result2 || values.result2 === "") {
        errors.result2 = "Bir seçenek şeçiniz.";
      }
      if (!values.result3 || values.result3 === "") {
        errors.result3 = "Bir seçenek şeçiniz.";
      }
      return errors;
    },
    onSubmit: async values => {
      if (!localStorage.getItem("token")) {
        toast.show("Öncelikle Giriş Yapmalısınız", "error");
      }
      formService.degerlemeAnket(values).then(res => {
        if (res?.result?.error === null) {
          toast.show("Anket kaydedildi.", "success");
        } else {
          toast.show("Anket kaydedilemedi", "error");
        }
      });
    }
  });

  const salesGrowthOptions = [
    { label: "%0 - %5 arasında artış", value: "0_5_artis" },
    { label: "%5 - %10 arasında artış", value: "5_10_artis" },
    { label: "%10 ve üzeri artış", value: "10_uzeri_artis" },
    { label: "%0 - %5 arasında azalış", value: "0_5_azalis" },
    { label: "%5 - %10 arasında azalış", value: "5_10_azalis" },
    { label: "%10 ve üzeri azalış", value: "10_uzeri_azalis" }
  ];

  const sustainableGrowthOptions = [
    { label: "%0 - %3 arasında büyüme", value: "0_3_buyume" },
    { label: "%3 - %5 arasında büyüme", value: "3_5_buyume" },
    { label: "%5 - %7 arasında büyüme", value: "5_7_buyume" },
    { label: "%7 ve üzeri büyüme", value: "7_uzeri_buyume" }
  ];

  const waccChangeOptions = [
    { label: "Azalacak", value: "azalacak" },
    { label: "Sabit kalacak", value: "sabit" },
    { label: "Hafifçe artacak", value: "hafif_artacak" },
    { label: "Ciddi şekilde artacak", value: "ciddi_artacak" }
  ];

  const handleCheckboxChange = (e: CheckboxChangeEvent, questionNumber: number) => {
    const value = e.value;
    let newAnalysisAreas: string = "";

    if (e.checked) {
      newAnalysisAreas = value;
    }
    // result1, result2, result3 için farklı şekilde setFieldValue
    if (questionNumber === 1) {
      formik.setFieldValue("result1", newAnalysisAreas);
    } else if (questionNumber === 2) {
      formik.setFieldValue("result2", newAnalysisAreas);
    } else if (questionNumber === 3) {
      formik.setFieldValue("result3", newAnalysisAreas);
    }
  };

  return (
    <>
      <div className="card">
        <div className="w-full surface-card">
          <div className="text-center mb-5">
            <div className="text-900 text-3xl font-medium mb-3">Değerleme</div>
          </div>
          <h5>Aşağıdaki değişkenleri şirketinize uyarlayarak şirketinizin değerini bulun.</h5>

          {/* <div className="iframe-container">
            <iframe src="https://www.beraberiz.biz/degerleme/index.html" height="350px" width="100%" loading="lazy"></iframe>
          </div> */}
          <div
            className="iframe-container"
            style={{
              margin: "0 auto", // Ortalansın diye
              padding: "0",
              border: "none",
              overflow: "hidden",
              maxWidth: "100%", // Mobilde taşmayı önler
              boxSizing: "border-box"
            }}
          >
            <iframe
              src="/modeller/degerleme/index copy.html"
              title="Iframe Example"
              loading="lazy"
              style={{
                border: "none",
                margin: "0",
                padding: "0",
                width: "100%",
                height: "100%" // Varsayılan yükseklik
              }}
              onLoad={e => {
                const iframe = e.target as HTMLIFrameElement;
                if (iframe.contentWindow && iframe.contentWindow.document.body) {
                  const body = iframe.contentWindow.document.body;
                  const html = iframe.contentWindow.document.documentElement;
                  const height = Math.max(
                    body.scrollHeight,
                    body.offsetHeight,
                    html.clientHeight,
                    html.scrollHeight,
                    html.offsetHeight
                  );
                  iframe.style.height = `${height}px`;
                }
              }}
            ></iframe>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="w-full surface-card">
          <div className="text-left mb-5 ml-5">
            <div className="text-900 text-3xl font-medium mb-3">Değerleme Anketi</div>
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-column justify-content-start ml-5">
              <h5>Şirketinizin gelecek yılki satış gelirlerinin artış oranı hakkında ne düşünüyorsunuz?</h5>
              <div className="grid">
                {salesGrowthOptions.map(salesGrowthOptions => (
                  <div key={salesGrowthOptions.value} className="col-12 ml-5">
                    <div className="flex align-items-center">
                      <Checkbox
                        inputId={salesGrowthOptions.value}
                        value={salesGrowthOptions.value}
                        onChange={e => handleCheckboxChange(e, 1)}
                        checked={formik.values.result1.includes(salesGrowthOptions.value)}
                      />
                      <label htmlFor={salesGrowthOptions.value} className="ml-2">
                        {salesGrowthOptions.label}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
              {FormikValueIsValid(formik, "result1") && <div className="p-error">{formik.errors.result1}</div>}

              <h5>Şirketinizin uzun vadede sürdürülebilir büyüme oranı hakkında beklentiniz nedir?</h5>
              <div className="grid">
                {sustainableGrowthOptions.map(sustainableGrowthOptions => (
                  <div key={sustainableGrowthOptions.value} className="col-12 ml-5">
                    <div className="flex align-items-center">
                      <Checkbox
                        inputId={sustainableGrowthOptions.value}
                        value={sustainableGrowthOptions.value}
                        onChange={e => handleCheckboxChange(e, 2)}
                        checked={formik.values.result2.includes(sustainableGrowthOptions.value)}
                      />
                      <label htmlFor={sustainableGrowthOptions.value} className="ml-2">
                        {sustainableGrowthOptions.label}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
              {FormikValueIsValid(formik, "result2") && <div className="p-error">{formik.errors.result2}</div>}

              <h5>Şirketinizin sermaye maliyetinin (WACC) gelecekte nasıl bir değişim göstereceğini düşünüyorsunuz?</h5>
              <div className="grid">
                {waccChangeOptions.map(waccChangeOptions => (
                  <div key={waccChangeOptions.value} className="col-12 ml-5">
                    <div className="flex align-items-center">
                      <Checkbox
                        inputId={waccChangeOptions.value}
                        value={waccChangeOptions.value}
                        onChange={e => handleCheckboxChange(e, 3)}
                        checked={formik.values.result3.includes(waccChangeOptions.value)}
                      />
                      <label htmlFor={waccChangeOptions.value} className="ml-2">
                        {waccChangeOptions.label}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
              {FormikValueIsValid(formik, "result3") && <div className="p-error">{formik.errors.result3}</div>}
              <Button label="Gönder" type="submit" className="mt-3 ml-auto w-8rem" />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Degerleme;
