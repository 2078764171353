import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import "i18next";
import { locale } from "primereact/api";
import { AddTrLocaleforPrimeReact } from "./AddTrLocaleforPrimeReact";

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

// localStorage'dan dil bilgisini kontrol ediyoruz
var i18nextLng = localStorage.getItem("i18nextLng");
if (!i18nextLng) {
  // Eğer localStorage'da dil yoksa, varsayılan olarak Türkçe'yi seçiyoruz
  localStorage.setItem("i18nextLng", "tr");
  i18nextLng = "tr";
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "tr",
    debug: false, // Geliştirme aşamasında hataları görmek için true yapabilirsiniz
    returnNull: false,
    backend: {
      retryTimeout: 500,
      retry: 3
    },
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage']
    },
    interpolation: {
      escapeValue: false,
    },
  });

// Dil değişikliği sadece localStorage'daki dile göre yapılır
i18n.changeLanguage(i18nextLng);
locale(i18nextLng); // PrimeReact için de doğru yerel ayar kullanılıyor

AddTrLocaleforPrimeReact();

export default i18n;
export const languages = {
  tr: "tr-TR",
  en: "en-US",
};
