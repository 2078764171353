import React from "react";
import EgitimQuestionForm from "./EgitimQuestionForm";
import CardSection from "./EgitimCardSection";
import EgitimVideo from "./EgitimVideo";
import { useTranslation } from "react-i18next";

const Egitim4 = () => {
  const { t } = useTranslation();
  return (
    <>
      <EgitimVideo videoUrl="https://youtu.be/vqrVuymINlo" header={t("LeadershipTrainingModel2")} />

      <EgitimQuestionForm questionName="Bir seçenek şeçiniz." />

      <CardSection />
    </>
  );
};

export default Egitim4;
