import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { Card } from "primereact/card";
import aosbLogo from "../../assets/images/aosb_ces_logo.jpg";
import formService from "../../services/forms/form.service";
import { AnketRequestDtoV1 } from "../../services/forms/form.dto";
import { useToast } from "../../utils/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";

interface Product {
  id: string;
  name: string;
  image: string;
  date: string;
  category: string;
  link: string;
}

const Raporlama = () => {
  const toast = useToast();

  const [raporlar, setRaporlar] = useState<Product[]>([]);

  useEffect(() => {
    setRaporlar([
      //   {
      //     id: "1",
      //     name: "Yeşil dönüşüm ve sürdürülebilirlik stratejik yönetim sistemi paydaş beklenti ve ihtiyaç analizi",
      //     image: "/reports/kapakfoto/rapor-kapak.png",
      //     date: "10/2023",
      //     category: "İç Rapor",
      //     link: "reports/rapor1.pdf"
      //   },
      {
        id: "2",
        name: "UNDP Afet Yönetimi, Risk Yönetimi ve İş Sürekliliği raporu",
        image: "/reports/kapakfoto/undp-kapak.png",
        date: "08/2023",
        category: "Dış Rapor",
        link: "reports/rapor1.pdf"
      },
      //   {
      //     id: "3",
      //     name: "ORGANİZE SANAYİ BÖLGELERİ İÇİN İŞ SÜREKLİLİĞİ PLANI HAZIRLAMA VE UYGULAMA KILAVUZU",
      //     image: "/reports/kapakfoto/rapor3kapak.png",
      //     date: "03/2020",
      //     category: "İç Rapor",
      //     link: "reports/rapor3.pdf"
      //   }
      {
        id: "4",
        name: "Support girls and women to pursue STEM subjects and careers",
        image: "/reports/kapakfoto/image.png",
        date: "11/2024",
        category: "Dış Rapor",
        link: "reports/391937eng.pdf"
      }
    ]);
  }, []);

  const imageBodyTemplate = (product: Product) => {
    return <img src={`${product.image}`} alt={product.image} className="w-4rem h-4rem shadow-2 border-round" />;
  };

  const downloadBodyTemplate = (product: Product) => {
    return <Button label="İndir" icon="pi pi-download" onClick={() => handleDownload(product)} />;
  };

  const handleDownload = (product: Product) => {
    // İndirme bağlantısını kontrol et
    if (product.link) {
      const fileName = product.link; // Örneğin: "reports/rapor1.pdf"
      const fullLink = `${process.env.PUBLIC_URL}/${fileName}`; // Tam URL'yi oluştur
      const link = document.createElement("a");
      link.href = fullLink; // Tam URL
      link.target = "_blank"; // Yeni sekmede açma
      link.download = product.name; // Dosya adı olarak ürünün adını kullanın
      document.body.appendChild(link);
      link.click(); // Bağlantıya tıkla
      document.body.removeChild(link); // Bağlantıyı temizle
    } else {
      toast.show("Dosya bağlantısı mevcut değil.", "error");
    }
  };

  const footer = `Toplam ${raporlar ? raporlar.length : 0} rapor bulunmakta.`;

  const formik = useFormik<AnketRequestDtoV1>({
    initialValues: {
      result1: ""
    },
    validate: values => {
      const errors: any = {};
      if (!values.result1 || values.result1 === "") {
        errors.result1 = "Bir seçenek şeçiniz.";
      }

      return errors;
    },
    onSubmit: async values => {
      //   formService.raporForm(values).then(res => {
      //     if (res?.result?.error === null) {
      //       toast.show("Anket kaydedildi.", "success");
      //     } else {
      //       //toast.show("Anket kaydedilemedi", "error");
      //     }
      //   });
    }
  });

  const options = [
    { label: "Karbon ayak izi", value: "karbon_ayak_izi" },
    { label: "Su ayak izi", value: "su_ayak_izi" },
    { label: "Ürün karbon ayak izi", value: "urun_karbon_ayak" },
    { label: "Ekolojik ayak izi", value: "ekolojik_ayak_izi" }
  ];

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    const value = e.value;
    let newAnalysisAreas: string = "";

    if (e.checked) {
      newAnalysisAreas = value;
    }

    formik.setFieldValue("result1", newAnalysisAreas);
  };

  return (
    <>
      <div className="card">
        <div className="text-center mb-5">
          <div className="text-900 text-3xl font-medium mb-3">Raporlar</div>
        </div>
        <div className="w-full surface-card">
          <DataTable
            value={raporlar}
            showGridlines
            footer={footer}
            tableStyle={{ minWidth: "60rem" }}
            rows={5} // Maksimum 5 satır gösterilecek şekilde ayarlandı
            paginator // Sayfalama eklemek için
            rowsPerPageOptions={[5, 10, 25, 50]}
            scrollable
            columnResizeMode="expand"
          >
            <Column field="name" header="Rapor Adı"></Column>
            <Column header="Resim" body={imageBodyTemplate}></Column>
            <Column field="date" header="Tarih"></Column>
            <Column field="category" header="Rapor Tipi"></Column>
            <Column header="İndirme" body={downloadBodyTemplate}></Column> {/* İndirme sütunu eklendi */}
          </DataTable>
        </div>
      </div>
    </>
  );
};

export default Raporlama;
