import React from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { Card } from "primereact/card";
import formService from "../../services/forms/form.service";
import { AnketRequestDtoV1 } from "../../services/forms/form.dto";
import { useToast } from "../../utils/toast";
import ReactPlayer from "react-player";
import { CheckboxChangeEvent, Checkbox } from "primereact/checkbox";
import { useTranslation } from "react-i18next";
import CardSection from "./EgitimCardSection";
import EgitimQuestionForm from "./EgitimQuestionForm";
import EgitimVideo from "./EgitimVideo";

const Egitim = () => {
  const toast = useToast();
  const { t } = useTranslation();

//   const formik = useFormik<AnketRequestDtoV1>({
//     initialValues: {
//       result1: ""
//     },
//     validate: values => {
//       const errors: any = {};
//       if (!values.result1 || values.result1 === "") {
//         errors.result1 = "Bir seçenek şeçiniz.";
//       }

//       return errors;
//     },
//     onSubmit: async values => {
//       if (!localStorage.getItem("token")) {
//         toast.show("Öncelikle Giriş Yapmalısınız", "error");
//       }
//       formService.egitimAnket(values).then(res => {
//         if (res?.result?.error === null) {
//           toast.show("Anket kaydedildi.", "success");
//         } else {
//           toast.show("Anket kaydedilemedi", "error");
//         }
//       });
//     }
//   });

//   const options = [
//     { label: "Çok Faydalı Oldu", value: "cok_oldu" },
//     { label: "Biraz Faydalı Oldu", value: "biraz" },
//     { label: "Faydalı Olmadı", value: "olmadı" }
//   ];

//   const handleCheckboxChange = (e: CheckboxChangeEvent) => {
//     const value = e.value;
//     let newAnalysisAreas: string = "";

//     if (e.checked) {
//       newAnalysisAreas = value;
//     }

//     formik.setFieldValue("result1", newAnalysisAreas);
//   };

  return (
    <>
      <EgitimVideo videoUrl="https://youtu.be/Yk3ElWMF4do" header={t("LeadershipTurkish")} />

      <EgitimQuestionForm questionName="Bir seçenek şeçiniz." />

      <CardSection />
      {/* <div className="card">
        <div className="text-center mb-5">
          <div className="text-900 text-3xl font-medium mb-3">Liderlik Türkçe</div>
        </div>

        <div className="flex justify-content-center">
          <div
            className="flex align-items-center"
            style={{
              width: "100%",
              position: "relative",
              aspectRatio: "16 / 9", // 16:9 oranı
              maxHeight: "80vh", // İsterseniz maksimum yükseklik ekleyebilirsiniz
              overflow: "hidden" // Taşmayı engelle
            }}
          >
            <ReactPlayer
              url="https://youtu.be/Yk3ElWMF4do"
              controls
              width="100%"
              height="100%"
              style={{ position: "absolute", top: 0, left: 0 }}
            />
          </div>
        </div>
      </div>

      <div className="card">
        <div className="w-full surface-card">
          <div className="text-center mb-5">
            <div className="text-900 text-3xl font-medium mb-3">Eğitim Anketi</div>
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-column align-items-start">
              <h5>Bu eğitim size ne kadar faydalı oldu?</h5>
              <div className="grid">
                {options.map(option => (
                  <div key={option.value} className="col-12 ml-3">
                    <Checkbox
                      inputId={option.value}
                      value={option.value}
                      onChange={handleCheckboxChange}
                      checked={formik.values.result1.includes(option.value)}
                    />
                    <label htmlFor={option.value} className="ml-2">
                      {option.label}
                    </label>
                  </div>
                ))}
              </div>

              {formik.errors.result1 && <div className="p-error">{formik.errors.result1}</div>}
              <Button label="Gönder" type="submit" className="mt-3" />
            </div>
          </form>
        </div>
      </div>

      <div className="card">
        <div className="w-full surface-card px-5 sm:px-8" style={{ borderRadius: "53px" }}>
          <div className="text-center mb-5">
            <div className="text-900 text-3xl font-medium mb-3">Linkler</div>
          </div>
          <div className="flex flex-column align-items-center justify-content-center ">
            <div className="grid grid-nogutter justify-content-center w-full p-2">
              <Card
                header={
                  <div className="flex flex-column align-items-center justify-content-center">
                    <span className="mb-2 font-bold text-xl text-black-alpha-90">{t("moodle")}</span>
                    <img className="w-12rem h-12rem mt-2" alt="Card" src="/images/1174944.webp" />
                  </div>
                }
                className="md:w-15rem h-15rem hover:shadow-3 cursor-pointer transition-all m-3"
                onClick={() => (window.location.href = "https://beraberiz.biz/okul/")}
              ></Card>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Egitim;
