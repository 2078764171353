import React from "react";
import { Button } from "primereact/button";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { useFormik } from "formik";
import { AnketRequestDtoV1 } from "../../services/forms/form.dto";
import formService from "../../services/forms/form.service";
import { useToast } from "../../utils/toast";
import { useTranslation } from "react-i18next";

const EgitimQuestionForm = (props: { questionName?: string }) => {
  const { t } = useTranslation();
  const toast = useToast();
  const options = [
    { label: t("educationSurvey.options.veryHelpful"), value: "veryHelpful" },
    { label: t("educationSurvey.options.somewhatHelpful"), value: "somewhatHelpful" },
    { label: t("educationSurvey.options.notHelpful"), value: "notHelpful" }
  ];

  const formik = useFormik<AnketRequestDtoV1>({
    initialValues: {
      result1: ""
    },
    validate: values => {
      const errors: any = {};
      if (!values.result1 || values.result1 === "") {
        errors.result1 = t("errors.noSelection");
      }

      return errors;
    },
    onSubmit: async values => {
      if (!localStorage.getItem("token")) {
        toast.show(t("errors.notLoggedIn"), "error");
      }
      try {
        const res = await formService.egitimAnket(values);
        if (res?.result?.error === null) {
          toast.show(t("messages.success"), "success");
        } else {
          toast.show(t("messages.failure"), "error");
        }
      } catch (error) {
        toast.show(t("messages.failure"), "error");
      }
    }
  });

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    const value = e.value;
    let newAnalysisAreas: string = "";
    if (e.checked) {
      newAnalysisAreas = value;
    }
    formik.setFieldValue("result1", newAnalysisAreas);
  };

  return (
    <div className="card">
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-column align-items-start">
          <h5>{t("educationSurvey.question")}</h5>
          <div className="grid">
            {options.map(option => (
              <div key={option.value} className="col-12 ml-3">
                <Checkbox
                  inputId={option.value}
                  value={option.value}
                  onChange={handleCheckboxChange}
                  checked={formik.values.result1.includes(option.value)}
                />
                <label htmlFor={option.value} className="ml-2">
                  {option.label}
                </label>
              </div>
            ))}
          </div>

          {formik.errors.result1 && <div className="p-error">{formik.errors.result1}</div>}
          <Button label={t("buttons.submit")} type="submit" className="mt-3" />
        </div>
      </form>
    </div>
  );
};

export default EgitimQuestionForm;
