import React from "react";
import { Button } from "primereact/button";
import { InputNumber, InputNumberValueChangeEvent } from "primereact/inputnumber";
import { useFormik } from "formik";
import formService from "../../services/forms/form.service";
import { HesaplamaAracAnketRequestDto } from "../../services/forms/form.dto";
import { useToast } from "../../utils/toast";
import FormikValueIsValid from "../../utils/FormikValueIsValid";

const KarbonAyakIzi = () => {
  //const toast = useRef<Toast>(null);
  const toast = useToast();

  const formik = useFormik<HesaplamaAracAnketRequestDto>({
    initialValues: {
      arac_sayisi: 0,
      arac_sayisi_alinan: 0,
      arac_faal_omur: 0,
      arac_pert_miktari: 0
    },
    validate: values => {
      const errors: any = {};

      if (values.arac_sayisi < 0) {
        errors.arac_sayisi = "Araç sayısı negatif olamaz.";
      }
      if (values.arac_sayisi_alinan < 0) {
        errors.arac_sayisi_alinan = "Alınan araç sayısı negatif olamaz.";
      }
      if (values.arac_faal_omur < 0) {
        errors.arac_faal_omur = "Araçların faal ömrü negatif olamaz.";
      }
      if (values.arac_pert_miktari < 0) {
        errors.arac_pert_miktari = "Pert olan araç miktarı negatif olamaz.";
      }

      if (values.arac_pert_miktari > values.arac_sayisi) {
        errors.arac_pert_miktari = "Pert olan araç miktarı toplam araç sayısından büyük olamaz.";
      }
      return errors;
    },
    onSubmit: async values => {
      if (!localStorage.getItem("token")) {
        toast.show("Öncelikle Giriş Yapmalısınız", "error");
      }
      formService.karbonAyakIziAnket(values).then(res => {
        if (res?.result?.error === null) {
          toast.show("Anket kaydedildi.", "success");
        } else {
          toast.show("Anket kaydedilemedi", "error");
        }
      });
    }
  });

  return (
    <>
      <div className="card">
        <div className="w-full surface-card">
          <div className="text-center mb-5">
            <div className="text-900 text-3xl font-medium mb-3">Karbon Ayak İzi</div>
          </div>
          <h5>
            Araç sayısının en yüksek sayıda tutularak en düşük seviyede yakıt tüketimi için hangi önlemeleri almak
            gerekli?
          </h5>

          <div
            className="iframe-container"
            style={{
              margin: "0 auto", // Ortalansın diye
              padding: "0",
              border: "none",
              overflow: "hidden",
              maxWidth: "100%", // Mobilde taşmayı önler
              boxSizing: "border-box"
            }}
          >
            <iframe
              src="/modeller/cor/index2.html"
              title="Iframe Example"
              loading="lazy"
              style={{
                border: "none",
                margin: "0",
                padding: "0",
                width: "100%",
                height: "100%" // Varsayılan yükseklik
              }}
              onLoad={e => {
                const iframe = e.target as HTMLIFrameElement;
                if (iframe.contentWindow && iframe.contentWindow.document.body) {
                  const body = iframe.contentWindow.document.body;
                  const html = iframe.contentWindow.document.documentElement;
                  const height = Math.max(
                    body.scrollHeight,
                    body.offsetHeight,
                    html.clientHeight,
                    html.scrollHeight,
                    html.offsetHeight
                  );
                  iframe.style.height = `${height}px`;
                }
              }}
            ></iframe>
          </div>

        </div>
      </div>

      <div className="w-full surface-card">
        <div className="text-center mb-5">
          <div className="text-900 text-3xl font-medium mb-3">Karbon Ayak İzi Anketi</div>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-column align-items-center justify-content-center">
            <div className="grid w-12 p-0 md:p-2">
              <div className="col-12 md:col-8 flex align-items-center">
                <label htmlFor="newEmail" className="text-900 text-xl font-medium mb-2">
                  OSB bünyesinde yer alan araç sayısı:
                </label>
              </div>

              <div className="col-12 md:col-4">
                <div className="p-inputgroup flex-1">
                  <InputNumber
                    inputId="arac_sayisi"
                    value={formik.values.arac_sayisi}
                    onChange={e => formik.setFieldValue("arac_sayisi", e.value)}
                    showButtons
                    min={0}
                  />
                </div>
              </div>
              {FormikValueIsValid(formik, "arac_sayisi") && (
                <div className="p-error mb-1">{formik.errors.arac_sayisi}</div>
              )}

              <div className="col-12 md:col-8 flex align-items-center">
                <label htmlFor="newEmail" className="text-900 text-xl font-medium mb-2">
                  OSB bünyesinde alınan araç sayısı:
                </label>
              </div>
              <div className="col-12 md:col-4">
                <div className="p-inputgroup flex-1">
                  <InputNumber
                    inputId="arac_sayisi_alinan"
                    value={formik.values.arac_sayisi_alinan}
                    onChange={e => formik.setFieldValue("arac_sayisi_alinan", e.value)}
                    showButtons
                    min={0}
                  />
                </div>
              </div>
              {FormikValueIsValid(formik, "arac_sayisi_alinan") && (
                <div className="p-error">{formik.errors.arac_sayisi_alinan}</div>
              )}

              <div className="col-12 md:col-8 flex align-items-center">
                <label htmlFor="newEmail" className="text-900 text-xl font-medium mb-2">
                  OSB bünyesindeki araçların faal ömrü:
                </label>
              </div>
              <div className="col-12 md:col-4">
                <div className="p-inputgroup flex-1">
                  <InputNumber
                    inputId="arac_faal_omur"
                    value={formik.values.arac_faal_omur}
                    onChange={e => formik.setFieldValue("arac_faal_omur", e.value)}
                    showButtons
                    min={0}
                  />
                </div>
              </div>

              {FormikValueIsValid(formik, "arac_faal_omur") && (
                <div className="p-error">{formik.errors.arac_faal_omur}</div>
              )}

              <div className="col-12 md:col-8 flex align-items-center">
                <label htmlFor="newEmail" className="text-900 text-xl font-medium mb-2">
                  OSB bünyesinde yıllık pert miktarı:
                </label>
              </div>
              <div className="col-12 md:col-4">
                <div className="p-inputgroup flex-1">
                  <InputNumber
                    inputId="arac_pert_miktari"
                    value={formik.values.arac_pert_miktari}
                    onChange={e => formik.setFieldValue("arac_pert_miktari", e.value)}
                    showButtons
                    min={0}
                  />
                </div>
              </div>

              {FormikValueIsValid(formik, "arac_pert_miktari") && (
                <div className="p-error">{formik.errors.arac_pert_miktari}</div>
              )}
              <div className="col-12 mt-5 ">
                <div className="flex justify-content-end">
                  <Button label="Gönder" type="submit" className="p-3 text-xl"></Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default KarbonAyakIzi;
