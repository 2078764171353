import React, { useContext } from "react";
import AppMenuitem from "./AppMenuitem";
import { LayoutContext } from "./context/layoutcontext";
import { MenuProvider } from "./context/menucontext";
import { AppMenuItem } from "../types/types";
import { useTranslation } from "react-i18next";

const AppMenu = () => {
  //const { layoutConfig } = useContext(LayoutContext);
  const { t } = useTranslation();

  const model: AppMenuItem[] = [
    {
        label: t("Home"),
        items: [{ label: t("Home"), icon: "pi pi-fw pi-home", to: "/" }]
      },
    // {
    //   label: "Sayfalar",
    //   icon: "pi pi-fw pi-briefcase",
    //   to: "/pages",
    //   items: [
    //     {
    //       label: "Dijital Modelleme",
    //       icon: "pi pi-fw pi-chart-bar",
    //       to: "/pages",
    //       items: [
    //         {
    //           label: "Karbon Ayak İzi",
    //           icon: "pi pi-fw pi-calculator",
    //           to: "/app/karbon"
    //         },
    //         {
    //           label: "Değerleme",
    //           icon: "pi pi-fw pi-file",
    //           to: "/app/degerleme"
    //         },
    //         {
    //           label: "Satış",
    //           icon: "pi pi-fw pi-briefcase",
    //           to: "/app/satis"
    //         }
    //       ]
    //     },
    //     {
    //       label: "Raporlar",
    //       icon: "pi pi-fw pi-file",
    //       to: "/app/raporlar"
    //     },
    //     {
    //       label: "Eğitim",
    //       icon: "pi pi-fw pi-graduation-cap",
    //       to: "/pages",
    //       items: [
    //         {
    //           label: "Liderlik Türkçe",
    //           icon: "pi pi-fw pi-book",
    //           to: "/app/egitim-1"
    //         },
    //         {
    //           label: "Strategy English",
    //           icon: "pi pi-fw pi-book",
    //           to: "/app/egitim-2"
    //         },
    //         {
    //           label: "Leadership Training Model",
    //           icon: "pi pi-fw pi-book",
    //           to: "/app/egitim-3"
    //         },
    //         {
    //           label: "Leadership Training Model 2",
    //           icon: "pi pi-fw pi-book",
    //           to: "/app/egitim-4"
    //         }
    //       ]
    //     }
    //   ]
    // },
    {
        label: t("DigitalModeling"),
        icon: "pi pi-fw pi-briefcase",
        to: "/pages",
        items: [
          {
            label: t("CarbonFootprint"),
            icon: "pi pi-fw pi-calculator",
            to: "/app/karbon"
          },
          {
            label: t("Valuation"),
            icon: "pi pi-fw pi-file",
            to: "/app/degerleme"
          },
          {
            label: t("Sales"),
            icon: "pi pi-fw pi-briefcase",
            to: "/app/satis"
          }
        ]
      },
      {
        label: t("Reports"),
        icon: "pi pi-fw pi-briefcase",
        to: "/pages",
        items: [
          {
            label: t("Reports"),
            icon: "pi pi-fw pi-file",
            to: "/app/raporlar"
          }
        ]
      },
      {
        label: t("Education"),
        icon: "pi pi-fw pi-briefcase",
        to: "/pages",
        items: [
          {
            label: t("LeadershipTurkish"),
            icon: "pi pi-fw pi-book",
            to: "/app/egitim-1"
          },
          {
            label: t("StrategyEnglish"),
            icon: "pi pi-fw pi-book",
            to: "/app/egitim-2"
          },
          {
            label: t("LeadershipTrainingModel"),
            icon: "pi pi-fw pi-book",
            to: "/app/egitim-3"
          },
          {
            label: t("LeadershipTrainingModel2"),
            icon: "pi pi-fw pi-book",
            to: "/app/egitim-4"
          }
        ]
      }
    ];

  return (
    <MenuProvider>
      <ul className="layout-menu">
        {model.map((item, i) => {
          return !item?.seperator ? (
            <AppMenuitem item={item} root={true} index={i} key={item.label} />
          ) : (
            <li className="menu-separator"></li>
          );
        })}
      </ul>
    </MenuProvider>
  );
};

export default AppMenu;
