import React, { useContext, useRef, useState } from "react";
import { StyleClass } from "primereact/styleclass";
import { Button } from "primereact/button";
import { Ripple } from "primereact/ripple";
import { Divider } from "primereact/divider";
import { LayoutContext } from "../../layout/context/layoutcontext";
import { NodeRef } from "../../types/index";
import { classNames } from "primereact/utils";
import { Link, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { InputNumber, InputNumberValueChangeEvent } from "primereact/inputnumber";
import { useFormik } from "formik";
import { Card } from "primereact/card";
import aosbLogo from "../../assets/images/aosb_ces_logo.jpg";
import formService from "../../services/forms/form.service";
import { AnketRequestDtoV4 } from "../../services/forms/form.dto";
import { useToast } from "../../utils/toast";
import FormikValueIsValid from "../../utils/FormikValueIsValid";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
// import { Toast } from "primereact/toast";

const Satis = () => {
  //const toast = useRef<Toast>(null);
  const toast = useToast();

  const formik = useFormik<AnketRequestDtoV4>({
    initialValues: {
      result1: "",
      result2: "",
      result3: "",
      result4: ""
    },
    validate: values => {
      const errors: any = {};
      if (!values.result1 || values.result1 === "") {
        errors.result1 = "Bir seçenek şeçiniz.";
      }
      if (!values.result2 || values.result2 === "") {
        errors.result2 = "Bir seçenek şeçiniz.";
      }
      if (!values.result3 || values.result3 === "") {
        errors.result3 = "Bir seçenek şeçiniz.";
      }
      if (!values.result4 || values.result4 === "") {
        errors.result4 = "Bir seçenek şeçiniz.";
      }
      return errors;
    },
    onSubmit: async values => {
      if (!localStorage.getItem("token")) {
        toast.show("Öncelikle Giriş Yapmalısınız", "error");
      }
      formService.satisAnket(values).then(res => {
        if (res?.result?.error === null) {
          toast.show("Anket kaydedildi.", "success");
        } else {
          toast.show("Anket kaydedilemedi", "error");
        }
      });
    }
  });

  const salesStrategyOptions = [
    { label: "Müşteri odaklı", value: "musteri_odakli" },
    { label: "Ürün odaklı", value: "urun_odakli" },
    { label: "Rekabet odaklı", value: "rekabet_odakli" },
    { label: "Fiyat odaklı", value: "fiyat_odakli" }
  ];

  const salesGoalFactors = [
    { label: "Pazar analizi", value: "pazar_analizi" },
    { label: "Müşteri ihtiyaçları", value: "musteri_ihtiyaclari" },
    { label: "Rakiplerin performansı", value: "rakiplerin_performansi" },
    { label: "Satış ekibinin geri bildirimi", value: "satis_ekibi_geri_bildirim" },
    { label: "Gelir hedefleri", value: "gelir_hedefleri" }
  ];

  const performanceImprovementMethods = [
    { label: "Eğitim ve gelişim programları", value: "egitim_gelisim" },
    { label: "Performans bazlı prim ve ödüller", value: "prim_odul" },
    { label: "Yeni satış araçlarının ve teknolojilerinin sağlanması", value: "satis_araclari" },
    { label: "Ekip içi motivasyon etkinlikleri", value: "motivasyon_etkinlikleri" },
    { label: "Satış süreçlerinin düzenli olarak analiz edilip iyileştirilmesi", value: "surec_analizi" }
  ];

  const successMetrics = [
    { label: "Satış hacmi", value: "satis_hacmi" },
    { label: "Müşteri memnuniyeti", value: "musteri_memnuniyeti" },
    { label: "Karlılık", value: "karlilik" },
    { label: "Yeni müşteri kazanımı", value: "yeni_musteri" },
    { label: "Satış döngüsü süresi", value: "satis_dongusu_suresi" }
  ];

  const handleCheckboxChange = (e: CheckboxChangeEvent, questionNumber: number) => {
    const value = e.value;
    let newAnalysisAreas: string = "";

    if (e.checked) {
      newAnalysisAreas = value;
    }

    if (questionNumber === 1) {
      formik.setFieldValue("result1", newAnalysisAreas);
    } else if (questionNumber === 2) {
      formik.setFieldValue("result2", newAnalysisAreas);
    } else if (questionNumber === 3) {
      formik.setFieldValue("result3", newAnalysisAreas);
    } else if (questionNumber === 4) {
      formik.setFieldValue("result4", newAnalysisAreas);
    }
  };

  return (
    <>
      <div className="card">
        <div className="w-full surface-card">
          <div className="text-center mb-5">
            <div className="text-900 text-3xl font-medium mb-3">Satış</div>
          </div>
          <h5>Aşağıdaki değişkenler ışığında en yüksek müşteri kazanımı sağlayın.</h5>

          <div
            className="iframe-container"
            style={{
              margin: "0 auto", // Ortalansın diye
              padding: "0",
              border: "none",
              overflow: "hidden",
              maxWidth: "100%", // Mobilde taşmayı önler
              boxSizing: "border-box"
            }}
          >
            <iframe
              src="/modeller/satis/index copy.html"
              title="Iframe Example"
              loading="lazy"
              style={{
                border: "none",
                margin: "0",
                padding: "0",
                width: "100%",
                height: "100%" // Varsayılan yükseklik
              }}
              onLoad={(e) => {
                const iframe = e.target as HTMLIFrameElement; 
                if (iframe.contentWindow && iframe.contentWindow.document.body) {
                  const body = iframe.contentWindow.document.body;
                  const html = iframe.contentWindow.document.documentElement;
                  const height = Math.max(
                    body.scrollHeight, 
                    body.offsetHeight, 
                    html.clientHeight, 
                    html.scrollHeight, 
                    html.offsetHeight
                  );
                  iframe.style.height = `${height}px`;
                }
              }}
            ></iframe>
          </div>
          
        </div>
      </div>

      <div className="card">
        <div className="w-full surface-card">
          <div className="text-left mb-5 ml-5">
            <div className="text-900 text-3xl font-medium mb-3">Satış Anketi</div>
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-column justify-content-start ml-5">
              <h5>1. Şirketinizin satış stratejisini nasıl tanımlarsınız?</h5>
              <div className="grid">
                {salesStrategyOptions.map(salesStrategyOption => (
                  <div key={salesStrategyOption.value} className="col-12 ml-5">
                    <div className="flex align-items-center">
                      <Checkbox
                        inputId={salesStrategyOption.value}
                        value={salesStrategyOption.value}
                        onChange={e => handleCheckboxChange(e, 1)}
                        checked={formik.values.result1.includes(salesStrategyOption.value)}
                      />
                      <label htmlFor={salesStrategyOption.value} className="ml-2">
                        {salesStrategyOption.label}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
              {FormikValueIsValid(formik, "result1") && <div className="p-error">{formik.errors.result1}</div>}

              <h5>
                2. Şirketinizde satış hedefleri belirlenirken aşağıdaki unsurlardan hangisi en önemli faktör olarak
                görülür?
              </h5>
              <div className="grid">
                {salesGoalFactors.map(salesGoalFactors => (
                  <div key={salesGoalFactors.value} className="col-12 ml-5">
                    <div className="flex align-items-center">
                      <Checkbox
                        inputId={salesGoalFactors.value}
                        value={salesGoalFactors.value}
                        onChange={e => handleCheckboxChange(e, 2)}
                        checked={formik.values.result2.includes(salesGoalFactors.value)}
                      />
                      <label htmlFor={salesGoalFactors.value} className="ml-2">
                        {salesGoalFactors.label}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
              {FormikValueIsValid(formik, "result2") && <div className="p-error">{formik.errors.result2}</div>}

              <h5>3. Satış ekibinizin performansını artırmak için en sık başvurduğunuz yöntem nedir?</h5>
              <div className="grid">
                {performanceImprovementMethods.map(performanceImprovementMethods => (
                  <div key={performanceImprovementMethods.value} className="col-12 ml-5">
                    <div className="flex align-items-center">
                      <Checkbox
                        inputId={performanceImprovementMethods.value}
                        value={performanceImprovementMethods.value}
                        onChange={e => handleCheckboxChange(e, 3)}
                        checked={formik.values.result3.includes(performanceImprovementMethods.value)}
                      />
                      <label htmlFor={performanceImprovementMethods.value} className="ml-2">
                        {performanceImprovementMethods.label}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
              {FormikValueIsValid(formik, "result3") && <div className="p-error">{formik.errors.result3}</div>}

              <h5>4. Şirketinizde satış başarısını değerlendirirken hangi metriklere öncelik verirsiniz?</h5>
              <div className="grid">
                {successMetrics.map(successMetrics => (
                  <div key={successMetrics.value} className="col-12 ml-5">
                    <div className="flex align-items-center">
                      <Checkbox
                        inputId={successMetrics.value}
                        value={successMetrics.value}
                        onChange={e => handleCheckboxChange(e, 4)}
                        checked={formik.values.result4.includes(successMetrics.value)}
                      />
                      <label htmlFor={successMetrics.value} className="ml-2">
                        {successMetrics.label}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
              {FormikValueIsValid(formik, "result4") && <div className="p-error">{formik.errors.result4}</div>}

              <Button label="Gönder" type="submit" className="mt-3 ml-auto w-8rem" />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Satis;
