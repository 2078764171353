import { AxiosRequestConfig } from "axios";
import http from "../base/http.service";
import {
  FormResponse,
  AnketRequestDtoV1,
  AnketRequestDtoV3,
  HesaplamaAracAnketRequestDto,
  AnketRequestDtoV4
} from "./form.dto";

const services = {
  karbonAyakIziAnket(dto: HesaplamaAracAnketRequestDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<FormResponse>("forms/anket-karbon", dto, config);
  },

  degerlemeAnket(dto: AnketRequestDtoV3, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<FormResponse>("forms/anket-degerleme", dto, config);
  },
  satisAnket(dto: AnketRequestDtoV4, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<FormResponse>("forms/anket-satis", dto, config);
  },

  egitimAnket(dto: AnketRequestDtoV1, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<FormResponse>("forms/egitim", dto, config);
  }
};

export default services;
