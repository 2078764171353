import React from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { Card } from "primereact/card";
import formService from "../../services/forms/form.service";
import { AnketRequestDtoV1 } from "../../services/forms/form.dto";
import { useToast } from "../../utils/toast";
import ReactPlayer from "react-player";
import { CheckboxChangeEvent, Checkbox } from "primereact/checkbox";
import { useTranslation } from "react-i18next";
import CardSection from "./EgitimCardSection";
import EgitimQuestionForm from "./EgitimQuestionForm";
import EgitimVideo from "./EgitimVideo";

const Egitim3 = () => {
    const { t } = useTranslation();

  return (
    <>
      <EgitimVideo videoUrl="https://youtu.be/MlZIVUBWC1I" header={t("LeadershipTrainingModel")} />

      <EgitimQuestionForm questionName="Bir seçenek şeçiniz." />

      <CardSection />
    </>
  );
};

export default Egitim3;
