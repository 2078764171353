import { addLocale } from "primereact/api";

export const AddTrLocaleforPrimeReact = () => {
  addLocale("tr", {
    startsWith: "Başlangıç",
    contains: "Barındırır",
    notContains: "İçinde Barındırmaz",
    endsWith: "Bitiş",
    equals: "Eşittir",
    notEquals: "Eşit Değildir",
    noFilter: "Filtresiz",
    filter: "Filtre",
    lt: "Daha az",
    lte: "Daha az veya Eşit",
    gt: "Daha Fazla",
    gte: "Daha fazla veya Eşit",
    dateIs: "Tarih",
    dateIsNot: "Tarih değildir",
    dateBefore: "Tarihten önce",
    dateAfter: "Tarihten sonra",
    custom: "Özel",
    clear: "Temiz",
    apply: "Uygula",
    matchAll: "Tümüyle eşleşir",
    matchAny: "Herhangi birine eşleşir",
    addRule: "Kural Ekle",
    removeRule: "Kuralı Sil",
    accept: "Tamam",
    reject: "İptal",
    choose: "Seç",
    upload: "Yükle",
    cancel: "Vazgeç",
    pending: "Askıda",
    fileSizeTypes: ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    dayNames: [
      "Pazar",
      "Pazartesi",
      "Salı",
      "Çarşamba",
      "Perşembe",
      "Cuma",
      "Cumartesi",
    ],
    dayNamesShort: ["Paz", "Pzt", "Sal", "Çar", "Per", "Cum", "Cmt"],
    dayNamesMin: ["Pz", "Pt", "Sa", "Ça", "Pe", "Cu", "Ct"],
    monthNames: [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ],
    monthNamesShort: [
      "Oca",
      "Şub",
      "Mar",
      "Nis",
      "May",
      "Haz",
      "Tem",
      "Ağu",
      "Eyl",
      "Eki",
      "Kas",
      "Ara",
    ],
    chooseYear: "Yıl Seçiniz",
    chooseMonth: "Ay Seçiniz",
    chooseDate: "Tarih Seçiniz",
    prevDecade: "Önceki On Yıl",
    nextDecade: "Gelecek On Yıl",
    prevYear: "Geçen Yıl",
    nextYear: "Gelecek Yıl",
    prevMonth: "Geçen Ay",
    nextMonth: "Gelecek Ay",
    prevHour: "Geçen Saat",
    nextHour: "Gelecek Saat",
    prevMinute: "Geçen Dakika",
    nextMinute: "Gelecek Dakika",
    prevSecond: "Geçen Saniye",
    nextSecond: "Gelecek Saniye",
    am: "ÖÖ",
    pm: "ÖS",
    today: "Bugün",
    now: "Şimdi",
    weekHeader: "Hf",
    firstDayOfWeek: 1,
    // showMonthAfterYear: false,
    dateFormat: "dd.mm.yy",
    weak: "Zayıf",
    medium: "Orta",
    strong: "Güçlü",
    passwordPrompt: "Parola Giriniz",
    emptyFilterMessage: "Kullanılabilir seçenek yok",
    emptyMessage: "Sonuç bulunamadı",
    aria: {
      trueLabel: "Doğru",
      falseLabel: "Yanlış",
      nullLabel: "Seçilmedi",
      star: "1 yıldız",
      stars: "{star} yıldız",
      selectAll: "Tüm ögeler seçildi",
      unselectAll: "Tüm ögelerden seçim kaldırıldı",
      close: "Kapat",
      previous: "Önceki",
      next: "Sonraki",
      navigation: "Navigasyon",
      scrollTop: "Yukarı çık",
      moveTop: "En üste taşı",
      moveUp: "Üste taşı",
      moveDown: "Aşağıya taşı",
      moveBottom: "En aşağıya taşı",
      moveToTarget: "Hedefe taşı",
      moveToSource: "Kaynağa taşı",
      moveAllToTarget: "Tümünü hedefe taşı",
      moveAllToSource: "Tümünü kaynağa taşı",
      pageLabel: "Sayfa {page}",
      firstPageLabel: "İlk Sayfa",
      lastPageLabel: "Son Sayfa",
      nextPageLabel: "Sonraki Sayfa",
      previousPageLabel: "Önceki Sayfa",
      rowsPerPageLabel: "Sayfa başına satır",
      jumpToPageDropdownLabel: "Açılan Sayfaya Git",
      jumpToPageInputLabel: "Giriş Yapılan Sayfaya git",
      selectRow: "Satır Seçildi",
      unselectRow: "Satır Seçilmedi",
      expandRow: "Satır Genişletildi",
      collapseRow: "Satır Daraltıldı",
      showFilterMenu: "Filtre Menüsünü Göster",
      hideFilterMenu: "Filtre Menüsünü Gizle",
      filterOperator: "Filtre Araçları",
      filterConstraint: "Filter Constraint",
      editRow: "Satırı Düzenle",
      saveEdit: "Düzenlemeyi Kaydet",
      cancelEdit: "Düzenlemeyi İptal Et",
      listView: "Liste Görünümü",
      gridView: "Izgara Görünümü",
      slide: "Slayt",
      slideNumber: "{slideNumber}",
      zoomImage: "Görüntüyü Yakınlaştır",
      zoomIn: "Yakınlaştır",
      zoomOut: "Uzaklaştır",
      rotateRight: "Sağa Döndür",
      rotateLeft: "Sola Döndür",
    },
  });
};
