import React from "react";
import { Card } from "primereact/card";
import { useTranslation } from "react-i18next";

const CardSection = () => {
  const { t } = useTranslation();

  return (
    // <div className="card">
    //   <div className="w-full surface-card px-5 sm:px-8" style={{ borderRadius: "53px" }}>
    //     <div className="text-center mb-5">
    //       <div className="text-900 text-3xl font-medium mb-3">{title}</div>
    //     </div>
    //     <div className="flex flex-column align-items-center justify-content-center">
    //       <div className="grid grid-nogutter justify-content-center w-full p-2">
    //         <Card
    //           header={
    //             <div className="flex flex-column align-items-center justify-content-center">
    //               <span className="mb-2 font-bold text-xl text-black-alpha-90">{t("moodle")}</span>
    //               <img className="w-12rem h-12rem mt-2" alt={altText} src={imageSrc} />
    //             </div>
    //           }
    //           className="md:w-15rem h-15rem hover:shadow-3 cursor-pointer transition-all m-3"
    //           onClick={() => (window.location.href = link)}
    //         ></Card>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="card">
      <div className="w-full surface-card px-5 sm:px-8" style={{ borderRadius: "53px" }}>
        <div className="text-center mb-5">
          <div className="text-900 text-3xl font-medium mb-3">{t("links")}</div>
        </div>
        <div className="flex flex-column align-items-center justify-content-center ">
          <div className="grid grid-nogutter justify-content-center w-full p-2">
            <Card
              header={
                <div className="flex flex-column align-items-center justify-content-center">
                  <span className="mb-2 font-bold text-xl text-black-alpha-90">{t("moodle")}</span>
                  <img className="w-12rem h-12rem mt-2" alt="Card" src="/images/1174944.webp" />
                </div>
              }
              className="md:w-15rem h-15rem hover:shadow-3 cursor-pointer transition-all m-3"
              onClick={() => (window.location.href = "https://beraberiz.biz/okul/")}
            ></Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardSection;
